export function parseJwt(token) {
    
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
    return JSON.parse(jsonPayload);
}


// let { exp } = parseJwt(token);
// if (exp * 1000 > Date.now()) {
//   localStorage.setItem("token", token);
//   this.$router.push("/");
// } else {
//   this.loading = false;
//   this.message = "Ops! Token already expired";
// }