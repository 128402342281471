<template>
  <div>
      <h2>File upload</h2>
      <input @change="onChange" type="file" >

      <button @click="onUpload">Upload</button>
  </div>
</template>

<script>
const AWS = require('aws-sdk');
//const fs = require('fs'); // Needed for example below

const spacesEndpoint = new AWS.Endpoint('sfo2.digitaloceanspaces.com');
const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: '7HRSJKCJDQD6JGHZ636F',
    secretAccessKey: 'joxPzULnfePT/uUz0ZarByK6Jp+jGEUITsHBFJCnnOQ'
});
export default {
    data() {
        return {
            file: new FormData
        }
    },
    methods: {
        onChange(e) {
        
            this.file = e.target.files[0];
           
        },
        onUpload() {
                var params = {
                Bucket: "kazi-blubird",
                Key: 'apical/'+this.file.name,
                Body: this.file,
                ACL: "private"
            };

            s3.putObject(params, function(err, data) {
                if (err) console.log(err, err.stack);
                else     console.log(data);
            });
        }
    }
}
</script>

<style>

</style>