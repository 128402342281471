<template>
  <div class="p-6" style="width: 650px;">
    <DialogTitle label="New Registration Running" align="center" />
    <div class="mt-4 mb-6">
      <span
        class="bg-link-water px-3 m-1 rounded-full text-primary text-base font-bold"
        >Registration</span
      >
      <span
        class="bg-link-water px-3  m-1 rounded-full text-success text-base font-bold bg-success-light"
        >New</span
      >
    </div>
    <p class="text-justify text-lg line-height-27">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia expedita nobis voluptate enim. Consequatur delectus, neque nesciunt unde earum in, voluptatem similique natus libero non nostrum? Maiores enim asperiores molestiae quod suscipit natus, optio placeat. Iusto nihil culpa ipsum vitae natus. Voluptates fugiat ea quisquam adipisci quidem blanditiis eveniet esse impedit nostrum odit saepe recusandae nemo qui officiis nobis corrupti, cupiditate illum obcaecati, repellat ex totam ut, eum exercitationem nisi. In, rerum enim minus labore atque dolore accusamus similique omnis expedita aperiam quasi a, dolores suscipit accusantium eius illo? Deserunt, ratione eaque sit, fugiat, quibusdam alias iure amet voluptates nihil quaerat et repellat! Beatae eligendi officiis natus accusantium, laboriosam quas ut ex? Cumque assumenda debitis voluptatibus sed soluta suscipit vero aspernatur. Aspernatur ut quidem cupiditate. Ipsum facilis odit similique aperiam! Natus quas impedit illo in. Incidunt impedit, facere, cum blanditiis necessitatibus, magnam id quia nam atque quasi deserunt recusandae ullam.</p>
    <div class="mt-6">
        <p class="text-uppercase text-victoria text-base mb-3">Update At</p>
        <h2>12 Sept, 2020</h2>
    </div>

    <div class="text-center mt-6">
        <md-button @click="dialog(false)" class="outline-gray-400">Back</md-button>
    </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import { mapMutations } from "vuex";

export default {
  components: { DialogTitle },
  methods: mapMutations({
      dialog: "setShowDialog",
    }),
};
</script>

<style></style>
