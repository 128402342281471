<template>
  <div>
    <div class="bg-link-water p-3 rounded">
      <div class="flex justify-between flex-wrap justify-sm-center justify-xs-center">
        <div class="m-1">
          <span class="text-primary text-uppercase font-bold text-2xl ml-2"
            >Quiz set</span
          >
        </div>
      </div>
    </div>
    <div class="button_body md-size-20">
        <span>
            <button class="bg-green text-white p-2">Let's Started</button>
            <!-- <md-tooltip v-if="item.is_default === 0" id="set_explation_tooltip" md-direction="top">Your study guide has not been approved by the admin now</md-tooltip> -->
        </span>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    
  },
  methods: {
    
  },
};
</script>

<style>
.button_body{
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.button_body .bg-green{
  border: 0px solid;
  font-size: 20px;
  border-radius: 10px;
  padding: 14px 20px !important;
}
</style>
