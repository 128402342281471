<template>
  <div class="mt-6">
    <h2 class="text-uppercase mb-3">Recover your password</h2>
    <md-divider></md-divider>
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-60 md-xsmall-size-100">
        <InputFieldComponent label="Email" class="my-4"/>
        <div class="flex justify-end">
            <md-button class="bg-victoria text-white rounded mr-0">Send Recover Code</md-button>
        </div>
      </div>
    </div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { InputFieldComponent } from "@/components"
import { parseJwt } from "@/utils/token-parser.js";
export default {
  components: {
    InputFieldComponent
  },
  data: () => ({
      message: null,
      boolean: false
  }),
  methods: {
    onLogin() {
      this.loading = true;
      this.message = null;
      let token = process.env.VUE_APP_TOKEN;
      if (!token) {
        console.log("Token not found");
        return;
      }
      let { exp } = parseJwt(token);
      if (exp * 1000 > Date.now()) {
        localStorage.setItem("token", token);
        this.$router.push("/");
      } else {
        this.loading = false;
        this.message = "Ops! Token already expired";
        localStorage.setItem("token", token);
        this.$router.push("/");
      }
    },
  },
};
</script>