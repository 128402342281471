<template>
  <div class="outline-gray-400 p-3 rounded-lg my-6">
    <h2 class="inline-block">New registration running</h2>
    <span
      class="bg-link-water px-3 m-1 rounded-full text-primary text-base font-bold"
      >Registration</span
    >
    <span
      class="bg-link-water px-3  m-1 rounded-full text-success text-base font-bold bg-success-light"
      >New</span
    >
    <p class="text-lg mt-2 text-italic text-gray-500">
      Updated at <strong>12 April, 2020</strong>
    </p>
    <p class="text-lg my-3 line-height-33">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi debitis
      ipsam adipisci ratione a harum maiores possimus facilis nemo blanditiis
      nisi, eos itaque quasi consequatur sequi sit eaque nihil? Necessitatibus,
      blanditiis ipsam ipsum magni sunt vero dicta iure ratione nam.
    </p>
    <p>
      <span class="text-lg text-italic font-bold mr-3">View Details</span>
      <span @click="$emit('click')" class="text-primary pointer text-lg">https://apical.in</span>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
